import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { toast } from 'react-toastify';

import {
  validarDadosAtualizacaoPaginaCatalogo,
  verificarAtualizacaoPaginas,
} from '../../../lib/inputUtils';
import api from '../../../services/api';

import { UploadTemporarioInput } from '../../../components/Form/UploadTemporarioInput';

import Loading from '../../../components/Loading';
import CreatableSelect from '../../../components/Form/Input/CreatableSelect';
import TitleBar from '../../../components/TitleBar';
import { Form } from '../../../components/Form';
import InputViewer from '../../../components/Form/Input/Viewer';
import Switch from '../../../components/Form/Input/Switch';

import {
  Container,
  WrapperCatalogInfo,
  WrapperInputs,
  WrapperInputPaginas,
  WrapperSwitch,
} from './styles';
import { AppError } from '../../../errors/AppError';

function CatalogoAtualizar() {
  const formRef = useRef(null);
  const location = useLocation();
  const { id } = location.state;

  const [loading, setLoading] = useState(false);
  const [catalogo, setCatalogo] = useState(null);
  const [insercaoNovaPagina, setInsercaoNovaPagina] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');

  const carregaCatalogo = useCallback(() => {
    api.get(`catalogo/${id}`).then((response) => {
      const { arquivo } = response.data;

      /** formata campo fabricante */
      const fmtFabricante = arquivo.fabricante && arquivo.fabricante.descricao;

      /** carrega as informações do catálogo */
      setCatalogo({
        ...response.data,
        fabricante: fmtFabricante,
      });
    });
  }, [id]);

  useEffect(() => {
    carregaCatalogo();
  }, [location, id, carregaCatalogo]);

  const handleFileUpload = useCallback(
    async (file) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileName', catalogo.arquivo.nome);

      try {
        const response = await api.post('uploads-temporario', formData, {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
          },
        });

        const { path, originalname } = response.data;

        formRef.current.setFieldValue('path', path);
        setPreviewUrl(originalname);
        toast.success('Arquivo carregado com sucesso!');
      } catch (err) {
        setPreviewUrl('Falha no carregamento do arquivo');
        AppError(err, formRef);
      } finally {
        setUploadProgress(null);
      }
    },
    [catalogo, setUploadProgress, setPreviewUrl]
  );

  const handleConfirmar = useCallback(
    async (data) => {
      setLoading(true);
      try {
        if (data.path.length === 0) {
          alert('Necessário carregar o arquivo primeiro');
          return;
        }

        /* Verificar se as páginas para atualizar estão dentro do número de páginas do catálogo atual */
        const atualizacaoPaginas = validarDadosAtualizacaoPaginaCatalogo(
          data.atualizarPaginas
        );

        /* Campo inserir paginas é opcinal, portanto necessario atribuição de [] */
        const inserirPaginas = validarDadosAtualizacaoPaginaCatalogo(
          data?.inserirPaginas || []
        );

        const numeracaoRepetida = atualizacaoPaginas.dadosValidos.some((e) =>
          inserirPaginas.dadosValidos.includes(e)
        );

        if (
          atualizacaoPaginas.dadosValidos.length === 0 &&
          atualizacaoPaginas.dadosInvalidos.length >= 0 &&
          inserirPaginas.dadosValidos.length === 0 &&
          inserirPaginas.dadosInvalidos.length >= 0
        ) {
          alert(
            'O campo número das páginas (novas ou atualizar) aceita apenas número (positivo) e não pode estar vazio'
          );
          return;
        }

        const {
          dentroRangeNumeracaoCatalogo,
          paginasAtualizacaoValidas,
        } = verificarAtualizacaoPaginas(
          atualizacaoPaginas.dadosValidos,
          catalogo.arquivo.numeroPagina
        );

        if (!dentroRangeNumeracaoCatalogo && data.atualizarPaginas.length > 0) {
          alert(
            'Os números informados das páginas para atualização não possuem neste catálogo'
          );
          return;
        }

        if (numeracaoRepetida) {
          alert('Campo (Atualizar) e (Novas) possuem números iguais');
          return;
        }

        await api.patch(`catalogo/${id}/pagina`, {
          fileName: catalogo.arquivo.nome,
          atualizarPaginas: paginasAtualizacaoValidas,
          inserirPaginas: inserirPaginas.dadosValidos,
        });

        toast.success('Cards das páginas atualizadas com sucesso');
      } catch (err) {
        toast.error('Não foi possível atualizar as páginas');
        AppError(err, formRef);
      } finally {
        setLoading(false);
      }
    },
    [catalogo, id]
  );

  const handleHabilitarAtualizacaoPaginaNova = useCallback(() => {
    setInsercaoNovaPagina(!insercaoNovaPagina);
  }, [insercaoNovaPagina]);

  return (
    <Container>
      <TitleBar back />

      <h2>Atualização Catálogo</h2>
      {catalogo && (
        <>
          <WrapperCatalogInfo>
            <Form initialData={catalogo}>
              <Form.Row>
                <InputViewer
                  id="idCatalogo"
                  name="idCatalogo"
                  label="Catálogo"
                />

                <InputViewer id="nome" name="nome" label="Classe Operacional" />
              </Form.Row>
              <Form.Row>
                <InputViewer
                  id="fabricante"
                  name="fabricante"
                  label="Fabricante"
                />

                <InputViewer id="versao" name="versao" label="Versão" />
              </Form.Row>
              <h3>Informações do arquivo</h3>
              <Form.Row>
                <InputViewer
                  id="arquivoUrl"
                  name="arquivo.url"
                  label="Arquivo"
                  type="link"
                />
                <InputViewer
                  id="arquivoNumeroPagina"
                  name="arquivo.numeroPagina"
                  label="Num. Páginas"
                />
              </Form.Row>
            </Form>
          </WrapperCatalogInfo>

          <WrapperSwitch>
            <Switch
              rigthText="Habilitar inserção de novas páginas"
              onChange={handleHabilitarAtualizacaoPaginaNova}
            />
          </WrapperSwitch>
          <Form
            id="formAtualizarCatalogo"
            ref={formRef}
            onSubmit={handleConfirmar}
            autoComplete="off"
          >
            <WrapperInputs>
              <UploadTemporarioInput
                id="path"
                name="path"
                onFileChange={handleFileUpload}
                uploadProgress={uploadProgress}
                previewUrl={previewUrl}
              />

              <WrapperInputPaginas>
                {insercaoNovaPagina && (
                  <CreatableSelect
                    id="inserirPaginas"
                    name="inserirPaginas"
                    label="Número das Páginas (Novas)"
                    placeholder="Insira o número da página e pressione enter..."
                    isMulti
                    width={400}
                  />
                )}

                <CreatableSelect
                  id="atualizarPaginas"
                  name="atualizarPaginas"
                  label="Número das Páginas (Atualizar)"
                  placeholder="Insira o número da página e pressione enter..."
                  isMulti
                  width={400}
                />
              </WrapperInputPaginas>
            </WrapperInputs>

            <Form.Footer>
              <button
                id="btn-submit"
                type="submit"
                form="formAtualizarCatalogo"
              >
                Executar
              </button>
            </Form.Footer>
          </Form>
        </>
      )}
      <Loading visible={loading} />
    </Container>
  );
}

export { CatalogoAtualizar };
