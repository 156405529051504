import React, { useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import api from '../../../../services/api';

import { signOut } from '../../../../store/modules/auth/actions';

import logotipo from '../../../../assets/logo.svg';
import MarcaDAgua from '../../../../assets/marca-dagua-assiste.svg';

import { AppError } from '../../../../errors/AppError';

import { Form } from '../../../../components/Form';
import Input from '../../../../components/Form/Input';

import { Container, Wrapper, Footer } from './styles';
import { opNumeroAcesso } from '../../../../lib/const';

const schema = Yup.object().shape({
  password: Yup.string().required('O campo senha é obrigatório'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Senhas diferentes')
    .required('O campo confirmar senha é obrigatório'),
});

function MobilePrimeiroAcesso() {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  // const location = useLocation();

  const profile = useSelector((state) => {
    const { userId, name, adm, empresa } = state.user.profile || {};

    return { userId, name, adm, empresa };
  });

  const handleTrocarSenha = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });
        const [{ idLogin }] = await api
          .get(`usuario/${profile.userId}/login`)
          .then((response) => response.data.usuarioLogin);

        if (idLogin === opNumeroAcesso.PRIMEIRO) {
          await api
            .patch(`usuario/${profile.userId}/primeiro-acesso/${idLogin}`, {
              novaSenha: data.password,
            })
            .then(() => {
              toast.success('Senha atualizada com sucesso!');
              dispatch(signOut(profile.name));
            });
        }
      } catch (err) {
        AppError(err, formRef);
      }
    },
    [dispatch, profile]
  );

  return (
    <Container>
      <img src={logotipo} alt="Assiste" />
      <Wrapper>
        <h1>Primeiro Acesso</h1>
        <Form
          schema={schema}
          ref={formRef}
          onSubmit={handleTrocarSenha}
          autoComplete="off"
        >
          <p>
            Por segurança, altere sua senha no primeiro acesso. Isso garante que
            sua conta esteja protegida.
          </p>
          <Form.Column>
            <Form.Row>
              <Input
                id="password"
                name="password"
                type="password"
                placeholder="Nova Senha"
              />
            </Form.Row>
            <Form.Row>
              <Input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                placeholder="Confirmar Senha"
              />
            </Form.Row>
          </Form.Column>

          <button id="btn-submit" type="submit">
            Trocar Senha
          </button>
        </Form>
      </Wrapper>

      <Footer>
        <img src={MarcaDAgua} alt="logotipo" />
      </Footer>
    </Container>
  );
}

export { MobilePrimeiroAcesso };
