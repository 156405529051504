/* eslint-disable no-plusplus */
import { opTipoDocumento } from './const';

export function numberInputFormat(e, formRef, minimumFractionDigits = 2) {
  const { id: field, value } = e.target;
  formRef.current.setFieldValue(
    field,
    Number(value).toFixed(minimumFractionDigits)
  );
}

export function debounce(callback, wait) {
  let timer;
  return (...args) => {
    const context = this;
    clearTimeout(timer);
    timer = setTimeout(() => callback.apply(context, args), wait);
  };
}

export function throttle(func, limit) {
  let lastFunc;
  let lastRan;
  return (...args) => {
    const context = this;

    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
}

export function decimalAdjust(val, exp) {
  let value = +val;
  const exponent = +exp;

  // Se exponent é indefinido ou zero...
  if (typeof exponent === 'undefined' || exponent === 0) {
    return Math.round(val);
  }
  // Se o valor não é um número ou o exponent não é inteiro...
  if (
    Number.isNaN(value) ||
    !(typeof exponent === 'number' && exponent % 1 === 0)
  ) {
    return NaN;
  }
  // Transformando para string
  value = value.toString().split('e');
  value = Math.round(
    +`${value[0]}e${value[1] ? +value[1] - exponent : -exponent}`
  );
  // Transformando de volta
  value = value.toString().split('e');
  return +`${value[0]}e${value[1] ? +value[1] + exponent : exponent}`;
}

// Regex para validação de string no formato CPF e CNPJ
const regexCPF = /^\d{3}.\d{3}.\d{3}-\d{2}$/;
const regexCNPJ = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/;

function soma(valorA, valorB) {
  return valorA + valorB;
}

function somenteNumero(value = '') {
  const match = value.toString().match(/\d/g);
  return Array.isArray(match) ? match.map(Number) : [];
}

/** cálculo base para CPF */
function baseCalculoCPF(n, i, x) {
  return n * (x - i);
}

function DVCPF(n, numbers) {
  const rest = n % numbers.length;
  return rest < 2 ? 0 : numbers.length - rest;
}

/** cálculo validador de CNPJ */
function DVCNPJ(x, numbers) {
  const slice = numbers.slice(0, x);
  let factor = x - 7;
  let sum = 0;

  for (let i = x; i >= 1; i--) {
    const n = slice[x - i];
    sum += n * factor--;
    if (factor < 2) factor = 9;
  }

  const result = 11 - (sum % 11);

  return result > 9 ? 0 : result;
}

/** validador de CPF */
export function validaCPF(value = '') {
  if (!value) return false;

  // Aceita receber o valor como string, número ou array com todos os dígitos
  const isString = typeof value === 'string';
  const validTypes =
    isString || Number.isInteger(value) || Array.isArray(value);

  // Elimina valores com formato inválido
  if (!validTypes) return false;

  // Filtro inicial para entradas do tipo string
  if (isString) {
    // Teste Regex para veificar se é uma string apenas dígitos válida
    const digitsOnly = /^\d{11}$/.test(value);
    // Teste Regex para verificar se é uma string formatada válida
    const validFormat = regexCPF.test(value);
    // Verifica se o valor passou em ao menos 1 dos testes
    const isValid = digitsOnly || validFormat;

    if (!isValid) return false;
  }

  const cpf = somenteNumero(value);

  if (cpf.length !== 11) return false;

  // elimina valores inválidos com todos os dígitos repetidos
  const items = [...new Set(cpf)];
  if (items.length === 1) return false;

  // separa número base do dígito verificador
  const base = cpf.slice(0, 9);
  const digits = cpf.slice(9);

  // cálculo sobre o número base
  const calc0 = base
    .map((n, i) => baseCalculoCPF(n, i, cpf.length - 1))
    .reduce(soma, 0);

  // primeiro dígito verificador
  const digit0 = DVCPF(calc0, cpf);

  // Valida primeiro digito verificador
  if (digit0 !== digits[0]) return false;

  // cálculo sobre o número base + primeiro dígito verificador
  const calc1 = base
    .concat(digit0)
    .map((n, i) => baseCalculoCPF(n, i, cpf.length))
    .reduce(soma, 0);

  // segundo dígito verificador
  const digit1 = DVCPF(calc1, cpf);

  // valida segundo dígito verificador
  return digit1 === digits[1];
}

/** validador de CNPJ */
function validaCNPJ(value = '') {
  if (!value) return false;

  // Aceita receber o valor como string, número ou array com todos os dígitos
  const isString = typeof value === 'string';
  const validTypes =
    isString || Number.isInteger(value) || Array.isArray(value);

  if (!validTypes) return false;

  // Filtro inicial para entradas do tipo string
  if (isString) {
    // Teste Regex para veificar se é uma string apenas dígitos válida
    const digitsOnly = /^\d{14}$/.test(value);
    // Teste Regex para verificar se é uma string formatada válida
    const validFormat = regexCNPJ.test(value);
    // Verifica se o valor passou em ao menos 1 dos testes
    const isValid = digitsOnly || validFormat;

    if (!isValid) return false;
  }

  const cnpj = somenteNumero(value);

  // Valida a quantidade de dígitos
  if (cnpj.length !== 14) return false;

  // Elimina inválidos com todos os dígitos iguais
  const items = [...new Set(cnpj)];
  if (items.length === 1) return false;

  // Separa os 2 últimos dígitos verificadores
  const digits = cnpj.slice(12);

  // Valida primeiro dígito verificador
  const digit0 = DVCNPJ(12, cnpj);
  if (digit0 !== digits[0]) return false;

  // Valida segundo dígito verificador
  const digit1 = DVCNPJ(13, cnpj);

  return digit1 === digits[1];
}

function validaEXT(value = '') {
  if (!value) return false;
  return true;
}

/** método de formatação para CPF */
function formataCPF(value = '') {
  // Verifica se o valor é válido
  const valid = validaCPF(value);

  if (!valid) return '';

  const numbers = somenteNumero(value);
  const text = numbers.join('');

  // Formatação do CPF: 999.999.999-99
  const format = text.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

  return format;
}

/** método de formatação para CNPJ */
function formataCNPJ(value = '') {
  // Verifica se o valor é válido
  const valid = validaCNPJ(value);

  // Se o valor não for válido, retorna vazio
  if (!valid) return '';

  // Elimina tudo que não é dígito
  const numbers = somenteNumero(value);
  const text = numbers.join('');

  // Formatação do CNPJ: 99.999.999/9999-99
  const format = text.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    '$1.$2.$3/$4-$5'
  );

  return format;
}

export function formataDocumento(tipoDocumento, valor) {
  switch (Number(tipoDocumento)) {
    case opTipoDocumento.CPF:
      return formataCPF(valor);
    case opTipoDocumento.CNPJ:
      return formataCNPJ(valor);
    default:
      return '';
  }
}

export function validaDocumento(tipoDocumento, valor) {
  const validation = { message: '', isValid: false };

  if (!valor) return validation;

  switch (Number(tipoDocumento)) {
    /** validação no formato de CPF */
    case opTipoDocumento.CPF:
      validation.isValid = validaCPF(valor);
      if (!validation.isValid) validation.message = 'CPF inválido';
      break;
    /** validação no formato de CNPJ */
    case opTipoDocumento.CNPJ:
      validation.isValid = validaCNPJ(valor);
      if (!validation.isValid) validation.message = 'CNPJ inválido';
      break;
    case opTipoDocumento.EXT:
      validation.isValid = validaEXT(valor);
      if (!validation.isValid) validation.message = 'EXT inválido';
      break;
    default:
      break;
  }

  return validation;
}

export function YupValidaDocumento(value, context) {
  if (!value) return false;
  const { tipoDocumento } = context.parent;
  /** processo de validação do documento */
  const validation = validaDocumento(tipoDocumento, value);
  /** retorna mensagem caso documento seja inválido */
  if (!validation.isValid)
    return context.createError({
      message: `${validation.message} para o campo "Número Documento"`,
      path: 'documento',
    });

  return true;
}

// Referência: https://pt.wikipedia.org/wiki/Cadastro_de_pessoas_f%C3%ADsicas
// Referência: https://pt.wikipedia.org/wiki/Cadastro_Nacional_da_Pessoa_Jur%C3%ADdica

export function splitArray(curArr, perArr) {
  const n = Math.ceil(curArr.length / perArr);
  return Array.from({ length: n }, (v, i) =>
    curArr.slice(i * perArr, i * perArr + perArr)
  );
}

/* Verificar se os dados são números positivos e maior que 0  */
export function validarDadosAtualizacaoPaginaCatalogo(paginas) {
  return paginas.reduce(
    (result, item) => {
      const numero = Number(item);

      if (!Number.isNaN(numero) && item.trim() !== '' && numero >= 0) {
        result.dadosValidos.push(numero);
      } else {
        result.dadosInvalidos.push(item);
      }
      return result;
    },
    { dadosValidos: [], dadosInvalidos: [] }
  );
}

/* Verificar se os números das páginas para atualização estão dentro do número de páginas do catálogo atual  */
export function verificarAtualizacaoPaginas(numeros, numeroPaginasCatalogo) {
  const paginasAtualizacaoValidas = [];
  let dentroRangeNumeracaoCatalogo = false;

  numeros.forEach((valor) => {
    if (valor > 0 && valor <= numeroPaginasCatalogo) {
      dentroRangeNumeracaoCatalogo = true;
      paginasAtualizacaoValidas.push(valor);
    }
  });

  return { dentroRangeNumeracaoCatalogo, paginasAtualizacaoValidas };
}
