import styled from 'styled-components';

export const Container = styled.div`
  > h2 {
    padding: 20px 10px 10px;
  }

  padding-bottom: 20px;
`;

export const WrapperCatalogInfo = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #dbe1e9;
  border-radius: 5px;
  padding: 20px 30px;
`;

export const WrapperInputs = styled.div`
  padding: 20px 0px;
  width: 100%;

  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const WrapperInputPaginas = styled.div``;

export const WrapperSwitch = styled.div`
  display: flex;
  justify-content: flex-end;
`;
