import { call, select, put, all, takeLatest } from 'redux-saga/effects';

import {
  adicionaCarrinhoSuccess,
  atualizaQuantidadeSuccess,
  atualizaItemOSSuccess,
  sharedManagementSet,
} from './actions';

import api from '../../../services/api';

function* adicionaCarrinho({
  idCatalogo,
  idPagina,
  idItem,
  idMaterial,
  quantidade = 1,
  codimate = null,
  imagemCatalogo,
  itemOS,
}) {
  const { extNumeOS, extItemOS } = yield select((state) => state.cart.order);
  const fmtItemOS = itemOS || extItemOS[0];

  /** verifica se existe o item no carrinho */
  const itemExistente = yield select((state) =>
    codimate
      ? state.cart.items.find(
          (item) =>
            Number(item.idCatalogo) === Number(idCatalogo) &&
            Number(item.idPagina) === Number(idPagina) &&
            String(item.idItem) === String(idItem) &&
            Number(item.materialFabricante.idMaterial) === Number(idMaterial) &&
            String(item.materialEmpresa.codimate) === String(codimate)
        )
      : state.cart.items.find(
          (item) =>
            Number(item.idCatalogo) === Number(idCatalogo) &&
            Number(item.idPagina) === Number(idPagina) &&
            String(item.idItem) === String(idItem) &&
            Number(item.materialFabricante.idMaterial) === Number(idMaterial)
        )
  );

  /** pega a quantidade atual */
  const quantidadeAtual = itemExistente ? itemExistente.quantidadeTotal : 0;

  /** adiciona mais um item */
  const quantidadeTotal = quantidadeAtual + quantidade;

  if (itemExistente) {
    /** atualiza quantidade */
    yield put(
      atualizaQuantidadeSuccess(
        idCatalogo,
        idPagina,
        idItem,
        idMaterial,
        quantidadeTotal,
        fmtItemOS,
        codimate
      )
    );
  } else {
    /** adiciona item ao carrinho */
    const response = yield call(
      api.get,
      `catalogo-publico/${idCatalogo}/pagina/${idPagina}/item/${idItem}/${idMaterial}`
    );

    const data = {
      ...response.data,
      materialEmpresa: response.data.materialEmpresa.find(
        (material) => String(material.codimate) === String(codimate)
      ),
      itemOS,
      quantidadeTotal: quantidade,
      imagemCatalogo,
    };

    yield put(adicionaCarrinhoSuccess(data));
  }
}

function* adicionaCarrinhoArray({ idCatalogo, arrayItens }) {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < arrayItens.length; i++) {
    const {
      idPagina,
      idItem,
      idMaterial,
      codimate,
      quantidade,
      imagemCatalogo,
    } = arrayItens[i];

    const { extNumeOS, extItemOS } = yield select((state) => state.cart.order);
    const itemOS = extNumeOS ? extItemOS[0] : null;

    /** verifica se existe o item no carrinho */
    const itemExistente = yield select((state) =>
      codimate
        ? state.cart.items.find(
            (item) =>
              Number(item.idCatalogo) === Number(idCatalogo) &&
              Number(item.idPagina) === Number(idPagina) &&
              String(item.idItem) === String(idItem) &&
              Number(item.materialFabricante.idMaterial) ===
                Number(idMaterial) &&
              String(item.materialEmpresa.codimate) === String(codimate)
          )
        : state.cart.items.find(
            (item) =>
              Number(item.idCatalogo) === Number(idCatalogo) &&
              Number(item.idPagina) === Number(idPagina) &&
              String(item.idItem) === String(idItem) &&
              Number(item.materialFabricante.idMaterial) === Number(idMaterial)
          )
    );

    /** pega a quantidade atual */
    const quantidadeAtual = itemExistente ? itemExistente.quantidadeTotal : 0;

    /** adiciona mais um item */
    const quantidadeTotal = quantidadeAtual + quantidade;

    if (itemExistente) {
      /** atualiza quantidade */
      yield put(
        atualizaQuantidadeSuccess(
          idCatalogo,
          idPagina,
          idItem,
          idMaterial,
          quantidadeTotal,
          itemOS,
          codimate
        )
      );
    } else {
      /** adiciona item ao carrinho */
      const response = yield call(
        api.get,
        `catalogo-publico/${idCatalogo}/pagina/${idPagina}/item/${idItem}/${idMaterial}`
      );

      const data = {
        ...response.data,
        materialEmpresa: response.data.materialEmpresa.find(
          (material) => String(material.codimate) === String(codimate)
        ),
        itemOS,
        quantidadeTotal: quantidade,
        imagemCatalogo,
      };

      yield put(adicionaCarrinhoSuccess(data));
    }
  }
}

function* atualizaQuantidade({
  idCatalogo,
  idPagina,
  idItem,
  idMaterial,
  quantidade,
  codimate,
}) {
  if (quantidade <= 0) return;

  yield put(
    atualizaQuantidadeSuccess(
      idCatalogo,
      idPagina,
      idItem,
      idMaterial,
      quantidade,
      codimate
    )
  );
}

function* atualizaItemOS({
  idCatalogo,
  idPagina,
  idItem,
  idMaterial,
  itemOS,
  codimate,
}) {
  yield put(
    atualizaItemOSSuccess(
      idCatalogo,
      idPagina,
      idItem,
      idMaterial,
      itemOS,
      codimate
    )
  );
}

function* sharedManagement(set) {
  yield put(sharedManagementSet(set));
}

export default all([
  takeLatest('@cart/ADD_REQUEST', adicionaCarrinho),
  takeLatest('@cart/ADD_ARRAY_REQUEST', adicionaCarrinhoArray),
  takeLatest('@cart/UPDATE_AMOUNT_REQUEST', atualizaQuantidade),
  takeLatest('@cart/UPDATE_ITEMOS_REQUEST', atualizaItemOS),
  takeLatest('@cart/SHARED_MANAGEMENT', sharedManagement),
]);
