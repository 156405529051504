import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import Icon from '../../../assets/upload.svg';

import { Container } from './styles';

export function UploadTemporarioInput({
  name,
  onFileChange,
  uploadProgress,
  previewUrl,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => inputRef.current?.dataset?.fileid || '',
      setValue: (ref, value) => {
        inputRef.current.dataset.fileid = value; // Define o valor no atributo `data-fileid`
      },
      clearValue: () => {
        inputRef.current.dataset.fileid = ''; // Limpa o valor
      },
    });
  }, [fieldName, registerField]);

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onFileChange(file);
    }
  };

  return (
    <Container>
      <label htmlFor={name}>
        <img src={Icon} alt="PDF" />
        {uploadProgress
          ? `${uploadProgress}%`
          : previewUrl || 'Seleciona o arquivo aqui'}

        <input
          type="file"
          id={name}
          accept="application/pdf"
          ref={inputRef}
          onChange={handleChange}
          {...rest}
        />
      </label>
    </Container>
  );
}

UploadTemporarioInput.propTypes = {
  name: PropTypes.string.isRequired,
  onFileChange: PropTypes.func.isRequired,
  uploadProgress: PropTypes.number,
  previewUrl: PropTypes.string,
};

UploadTemporarioInput.defaultProps = {
  uploadProgress: null,
  previewUrl: null,
};
