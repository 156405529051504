import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  MdDelete,
  MdChevronLeft,
  MdChevronRight,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
  MdOutlineEmail,
  MdOutlineShare,
  // MdWhatsapp,
} from 'react-icons/md';
import { FaFileCsv } from 'react-icons/fa';

// import { set } from 'date-fns';
import { toast } from 'react-toastify';
import { AppError } from '../../../../errors/AppError';

import {
  atualizaQuantidadeRequest,
  removeCarrinho,
  limpaCarrinho,
} from '../../../../store/modules/cart/actions';

import history from '../../../../services/history';
import api from '../../../../services/api';

import {
  opEmpresa,
  opTipoAcao,
  // opTipoAcesso
} from '../../../../lib/const';
import { sendServerEmail } from '../../../../lib/paramUtils';
import { toCSV } from '../../../../lib/csv-core';

import TitleBar from '../../../../components/TitleBar';

import { Form } from '../../../../components/Form';
import InputViewer from '../../../../components/Form/Input/Viewer';

import { emailTipo } from '../../../Usuario/_usuario-email';

import { Main, NotFound, Section, Table, Footer, ItemRow } from './styles';

export function MobileCarrinho() {
  const profile = useSelector((state) => {
    const { userId, name = '', adm, empresa } = state.user.profile || {};
    const { idEmpresa, url: logotipo } = empresa || {};

    return { userId, name, adm, idEmpresa, url: logotipo };
  });

  const { empresa } = useSelector((state) => state.user.profile || {});

  const carrinho = useSelector((state) =>
    state.cart.items.map((item) => ({
      ...item,
      ...item.materialFabricante,
    }))
  );

  const location = useLocation();
  const dispatch = useDispatch();

  const [openInfo, setOpenInfo] = useState(false);
  const [indexToOpen, setIndexToOpen] = useState();

  // const [urlShared, setUrlShared] = useState(null);

  const itensCarrinho = useSelector((state) => {
    const { cart, user } = state;

    /** dados do carrinho */
    const {
      items,
      order: {
        extCodiDiv2,
        extNumeOS,
        extItemOS,
        extObservacao,
        extUsuario,
        extExtra01,
        extExtra02,
        extExtra03,
        extExtra04,
      },
    } = cart;

    /** dados do usuário */
    const { profile } = user || {};
    const { name = '' } = profile || {};

    /** percorre todos os itens do carrinho */
    const itens = items.map((item) => {
      const {
        materialFabricante,
        paginaCatalogo: {
          catalogo: { modelo },
        },
      } = item;

      return {
        ...item,
        ...materialFabricante,
        itemOSFormatado: extItemOS.map((sequencia) => ({
          value: sequencia,
          label: sequencia,
        })),
        modelo: modelo.map((m) => m.descricao).join(', '),
      };
    });

    const extObservacaoFormatada = extObservacao || '';

    return {
      extCodiDiv2,
      extNumeOS,
      extObservacao: extObservacaoFormatada.split('$n').join('\r\n'),
      extUsuario,
      extExtra01,
      extExtra02,
      extExtra03,
      extExtra04,
      nome: name,
      itens,
    };
  });

  const { app, action } = useSelector((state) => state.auth || {});

  const handleExcluir = useCallback(
    (idCatalogo, idPagina, idItem, idMaterial, materialEmpresa) => {
      setOpenInfo(false);
      const codimate = materialEmpresa ? materialEmpresa.codimate : null;

      dispatch(
        removeCarrinho(idCatalogo, idPagina, idItem, idMaterial, codimate)
      );
    },
    [dispatch]
  );

  const handleAdicionar = useCallback(
    (
      idCatalogo,
      idPagina,
      idItem,
      idMaterial,
      materialEmpresa,
      quantidade = 1
    ) => {
      const codimate = materialEmpresa ? materialEmpresa.codimate : null;

      const itemIndex = codimate
        ? itensCarrinho.itens.findIndex(
            (item) =>
              item.idCatalogo === idCatalogo &&
              item.idPagina === idPagina &&
              item.idItem === idItem &&
              item.idMaterial === idMaterial &&
              item.materialEmpresa.codimate === codimate
          )
        : itensCarrinho.itens.findIndex(
            (item) =>
              item.idCatalogo === idCatalogo &&
              item.idPagina === idPagina &&
              item.idItem === idItem &&
              item.idMaterial === idMaterial
          );

      if (itemIndex >= 0) {
        const quantidadeTotal =
          itensCarrinho.itens[itemIndex].quantidadeTotal + quantidade;

        dispatch(
          atualizaQuantidadeRequest(
            idCatalogo,
            idPagina,
            idItem,
            idMaterial,
            quantidadeTotal,
            codimate
          )
        );
      }
    },
    [itensCarrinho, dispatch]
  );

  const handleRemover = useCallback(
    (
      idCatalogo,
      idPagina,
      idItem,
      idMaterial,
      materialEmpresa,
      quantidade = 1
    ) => {
      const codimate = materialEmpresa ? materialEmpresa.codimate : null;

      const itemIndex = codimate
        ? itensCarrinho.itens.findIndex(
            (item) =>
              item.idCatalogo === idCatalogo &&
              item.idPagina === idPagina &&
              item.idItem === idItem &&
              item.idMaterial === idMaterial &&
              item.materialEmpresa.codimate === codimate
          )
        : itensCarrinho.itens.findIndex(
            (item) =>
              item.idCatalogo === idCatalogo &&
              item.idPagina === idPagina &&
              item.idItem === idItem &&
              item.idMaterial === idMaterial
          );

      if (itemIndex >= 0) {
        const quantidadeTotal =
          itensCarrinho.itens[itemIndex].quantidadeTotal - quantidade;

        dispatch(
          atualizaQuantidadeRequest(
            idCatalogo,
            idPagina,
            idItem,
            idMaterial,
            quantidadeTotal,
            codimate
          )
        );
      }
    },
    [itensCarrinho, dispatch]
  );

  const handleExportar = useCallback(
    async (whatsapp) => {
      const data = itensCarrinho.itens.map((item) => {
        const {
          materialEmpresa,
          partnumber,
          descricao,
          especTecnica,
          modelo,
          fabricante: { descricao: fabricante },
          quantidade,
          quantidadeTotal,
          paginaCatalogo: {
            descricao: pagina,
            catalogo: { nome: catalogo, versao },
          },
        } = item;

        const {
          codimate: codimateERP = null,
          descricao: descricaoERP = null,
          unidade: unidadeERP = null,
        } = materialEmpresa || {};

        return [
          codimateERP,
          descricaoERP,
          unidadeERP,
          partnumber,
          descricao,
          especTecnica,
          modelo,
          fabricante,
          quantidade,
          quantidadeTotal,
          pagina,
          catalogo,
          versao,
        ];
      });

      const fields = [
        'Código Material (ERP)',
        'Descrição (ERP)',
        'Unidade (ERP)',
        'Partnumber',
        'Descrição',
        'Espec. Técnica',
        'Modelo',
        'Fabricante',
        'Quantidade Catálogo',
        'Quantidade Solicitada',
        'Página',
        'Catálogo',
        'Versão',
      ];

      const csv = toCSV(fields, data);

      try {
        /** cria um link para o processo de download */
        const link = document.createElement('a');
        const file = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csv)}`;

        link.href = file;
        link.download = `catalogo-eletronico-carrinho.csv`;

        document.body.appendChild(link);

        if (whatsapp) {
          return { download: link.download, href: link.href };
        }

        // if (whatsapp) {
        //   const csvWhatsApp = toCSV(fields, data);
        //   const blobFile = new Blob([`\uFEFF${csvWhatsApp}`], {
        //     type: 'text/csv;charset=utf-8;',
        //   });
        //   const formData = new FormData();
        //   formData.append('file', blobFile);

        //   const response = await api.post('email', formData);
        //   const { path } = response.data;

        //   return path;
        // }

        link.click();

        document.body.removeChild(link);
      } catch (err) {
        AppError(err);
      }
    },
    [itensCarrinho]
  );

  const handleEmail = useCallback(
    async (paraEmpresa = false) => {
      const data = itensCarrinho.itens.map((item) => {
        const {
          materialEmpresa,
          partnumber,
          descricao,
          especTecnica,
          modelo,
          fabricante: { descricao: fabricante },
          quantidade,
          quantidadeTotal,
          paginaCatalogo: {
            descricao: pagina,
            catalogo: { nome: catalogo, versao },
          },
        } = item;

        const {
          codimate: codimateERP = null,
          descricao: descricaoERP = null,
          unidade: unidadeERP = null,
        } = materialEmpresa || {};

        return [
          codimateERP,
          descricaoERP,
          unidadeERP,
          partnumber,
          descricao,
          especTecnica,
          modelo,
          fabricante,
          quantidade,
          quantidadeTotal,
          pagina,
          catalogo,
          versao,
        ];
      });

      const fields = [
        'Código Material (ERP)',
        'Descrição (ERP)',
        'Unidade (ERP)',
        'Partnumber',
        'Descrição',
        'Espec. Técnica',
        'Modelo',
        'Fabricante',
        'Quantidade Catálogo',
        'Quantidade Solicitada',
        'Página',
        'Catálogo',
        'Versão',
      ];

      try {
        const csv = toCSV(fields, data);
        const blobFile = new Blob([`\uFEFF${csv}`], {
          type: 'text/csv;charset=utf-8;',
        });

        /** verifica parâmetro para envio de e-mail pelo servidor ou serviço próprio */
        if (sendServerEmail()) {
          history.push('/enviar-email', {
            background: location,
            tipo: emailTipo.EMAIL_CARRINHO,
            paraEmpresa,
            data: { attachment: blobFile },
          });
        } else {
          const formData = new FormData();
          formData.append('file', blobFile);

          const response = await api.post('email', formData);
          const { path } = response.data;

          const subject = encodeURIComponent(
            `Catálogo Eletrônico - Lista de Compras`
          );
          const body = encodeURIComponent(
            `Olá,\n\nSegue link para download da lista de compras:\n${path}\n\nAssiste - Catálogo Eletrônico`
          );

          /** cria um link para o processo de download */
          const link = document.createElement('a');
          link.href = `mailto:nome@endereco.com?subject=${subject}&body=${body}`;

          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
        }
      } catch (err) {
        AppError(err);
      }
    },
    [location, itensCarrinho]
  );

  const handleConfirmar = useCallback(async () => {
    /** solicitação por token */
    if (action === opTipoAcao.INTEGRACAO_TOKEN) {
      handleEmail(true);
      return;
    }

    try {
      const {
        extCodiDiv2,
        extNumeOS,
        extObservacao,
        extUsuario,
        extExtra01,
        extExtra02,
        extExtra03,
        extExtra04,
      } = itensCarrinho;

      const response = await api.post('/solicitacao', {
        extCodiDiv2,
        extNumeOS,
        extObservacao,
        extUsuario,
        extExtra01,
        extExtra02,
        extExtra03,
        extExtra04,
        itensCarrinho: itensCarrinho.itens,
      });

      const { idSolicitacao } = response.data;

      /** limpa itens do carrinho */
      dispatch(limpaCarrinho());

      /** redireciona para página de solicitação */
      history.push('/checkout', { id: idSolicitacao });
    } catch (err) {
      AppError(err);
    }
  }, [dispatch, itensCarrinho, action, handleEmail]);

  const handleOpenInfo = (index) => {
    if (indexToOpen !== index && openInfo === true) {
      setOpenInfo(false);
      setIndexToOpen(index);
      setOpenInfo(true);
    } else if (indexToOpen === index) {
      setOpenInfo(!openInfo);
    } else {
      setIndexToOpen(index);
      setOpenInfo(!openInfo);
    }
  };

  const redirectToPage = useCallback((idCatalogo, idPagina) => {
    api
      .get(`/catalogo/${idCatalogo}/nivel-classificacao/pagina/${idPagina}`)
      .then((response) => {
        const { data } = response;

        const {
          idNivel,
          relacNivel: { nivel },
        } = data;

        history.push(
          `/catalogo.partes/${idCatalogo}?IDN_${nivel}=${idNivel}&IDP=${idPagina}`
        );
      })
      .catch((erro) => {
        console.error(erro);
      });
  }, []);

  const handleShared = useCallback(async () => {
    navigator.vibrate(200);

    const { userId, name, idEmpresa } = profile;

    const obs = `Token de compartilhamento criado pelo usuário ${userId} - ${name}`;

    const { idCatalogo } = itensCarrinho.itens[0];

    const itemQtdForToken = carrinho.map((i) => {
      const {
        idItem,
        quantidadeTotal,
        idMaterial,
        idPagina: idPag,
        codimate,
        imagemCatalogo,
      } = i;

      return `&IDPAG_${idItem}=${idPag}&IDI=${idItem}&IDMAT_${idItem}=${idMaterial}&CODMAT_${idItem}=${codimate}&QTD_${idItem}=${quantidadeTotal}&IMG_${idItem}=${imagemCatalogo}`;
    });

    const { data } = await api.post(
      `empresa/${idEmpresa}/catalogo/${idCatalogo}/token-acesso`,
      {
        nome: 'Compartilhamento',
        duracao: 3, // dias
        observacao: obs,
        tipo: 0,
        info: itemQtdForToken,
      }
    );

    if (!data) {
      toast.error(
        'Erro na geração do token de compartilhamento, tente novamente mais tarde!'
      );
    }

    const { nanoId } = data; // token

    const { pathname, search } = location;

    // const locationShared = window.location.href.replaceAll(
    //   `${pathname}${search}`,
    //   `/catalogo-token?token=${token}`
    // );

    const locationSharedNanoId = window.location.href.replaceAll(
      `${pathname}${search}`,
      `/catalogo-token?ass=${nanoId}`
    );

    // const fmtLocationShared = locationShared.replaceAll(',', '');
    const fmtLocationSharedNanoId = locationSharedNanoId.replaceAll(',', '');

    // const urlShared = `${fmtLocationShared}${carrinho.map((i) => {
    //   const { idItem, quantidadeTotal } = i;
    //   return `&IDI=${idItem}&IT_${idItem}QTD=${quantidadeTotal}`;
    // })}`;

    // const fmtUrlShared = urlShared.replaceAll(',', '');

    // setUrlShared(fmtLocationSharedNanoId);

    if (navigator.canShare) {
      try {
        await navigator
          .share({
            title: 'CDI - Catálogo Digital Interativo',
            text: 'Catálogo disponibilizado pela Assiste Engenharia',
            url: fmtLocationSharedNanoId,
          })
          .then(() => console.log('Compartilhado com sucesso!'));
      } catch (error) {
        console.log(error);
        toast.info('Volte para compartilhar quando desejar!');
      }
    } else {
      alert('A API de compartilhamento não é suportada nesse browser.');
    }
  }, [carrinho, itensCarrinho, location, profile]);

  return (
    <Main>
      <Main.Container>
        <TitleBar back title="Materiais selecionados" width={950} />
        <Main.Wrapper>
          {itensCarrinho.itens.length > 0 ? (
            <Form initialData={itensCarrinho} onSubmit={handleConfirmar}>
              {itensCarrinho.itens.map((item, index) => (
                <>
                  <ItemRow key={index}>
                    <ItemRow.Btn
                      type="button"
                      onClick={() => handleOpenInfo(index)}
                    >
                      {openInfo && indexToOpen === index ? (
                        <MdOutlineKeyboardArrowUp size={25} />
                      ) : (
                        <MdOutlineKeyboardArrowDown size={25} />
                      )}
                    </ItemRow.Btn>
                    <strong>{item.descricao}</strong>
                    <ItemRow.BtnWrapper>
                      <strong>
                        <Table.Quantity>
                          <button
                            type="button"
                            onClick={() =>
                              handleRemover(
                                item.idCatalogo,
                                item.idPagina,
                                item.idItem,
                                item.idMaterial,
                                item.materialEmpresa
                              )
                            }
                          >
                            <MdChevronLeft size={25} />
                          </button>
                          <span>{item.quantidadeTotal}</span>
                          <button
                            type="button"
                            onClick={() =>
                              handleAdicionar(
                                item.idCatalogo,
                                item.idPagina,
                                item.idItem,
                                item.idMaterial,
                                item.materialEmpresa
                              )
                            }
                          >
                            <MdChevronRight size={25} />
                          </button>
                        </Table.Quantity>
                      </strong>
                      <Table.Action>
                        <Table.BtnRemove
                          type="button"
                          onClick={() =>
                            handleExcluir(
                              item.idCatalogo,
                              item.idPagina,
                              item.idItem,
                              item.idMaterial,
                              item.materialEmpresa
                            )
                          }
                        >
                          <MdDelete size={20} />
                        </Table.BtnRemove>
                      </Table.Action>
                    </ItemRow.BtnWrapper>
                  </ItemRow>
                  {openInfo && indexToOpen === index && (
                    <ItemRow.InfoWrapper
                      key={item.partnumber}
                      onClick={() =>
                        redirectToPage(item.idCatalogo, item.idPagina)
                      }
                    >
                      <span>
                        {' '}
                        <img src={item.imagemCatalogo} alt="Imagem catálogo" />
                      </span>
                      <ItemRow.Container>
                        <strong>{item.descricao}</strong>
                        <span>{`Modelo: ${item.modelo}`}</span>
                        <span>{`Fabricante: ${item.fabricante.descricao}`}</span>
                        {item.materialEmpresa ? (
                          <>
                            <span>{`Código ERP: ${item.materialEmpresa.codimate}`}</span>
                            {item.materialEmpresa.paralelo === '1' && (
                              <Table.Error>
                                Atenção: O material selecionado está cadastrado
                                como não original
                              </Table.Error>
                            )}
                          </>
                        ) : (
                          <Table.Error>
                            Nenhum Código ERP relacionado com esse material para
                            integração
                          </Table.Error>
                        )}
                      </ItemRow.Container>
                    </ItemRow.InfoWrapper>
                  )}
                </>
              ))}
              <Section>
                <Form.Title>Outras Informações</Form.Title>
                <Form.Column>
                  <Form.Row>
                    <InputViewer
                      id="nome"
                      name="nome"
                      label="Solicitante:"
                      asRow
                    />
                  </Form.Row>
                  {itensCarrinho.extNumeOS && (
                    <>
                      <InputViewer
                        id="extCodiDiv2"
                        name="extCodiDiv2"
                        isHidden
                      />
                      <Form.Row>
                        <InputViewer
                          id="extNumeOS"
                          name="extNumeOS"
                          label="Nº OS:"
                          asRow
                        />
                      </Form.Row>
                    </>
                  )}
                  {itensCarrinho.extUsuario && (
                    <Form.Row>
                      <InputViewer
                        id="extUsuario"
                        name="extUsuario"
                        label="Usuário:"
                        asRow
                      />
                    </Form.Row>
                  )}
                  {itensCarrinho.extExtra01 && (
                    <Form.Row>
                      <InputViewer
                        id="extExtra01"
                        name="extExtra01"
                        label="Nº Requisição:"
                        asRow
                      />
                    </Form.Row>
                  )}
                  {itensCarrinho.extExtra02 && (
                    <Form.Row>
                      <InputViewer
                        id="extExtra02"
                        name="extExtra02"
                        label="Nº Tarefa:"
                        asRow
                      />
                    </Form.Row>
                  )}
                  {itensCarrinho.extExtra03 && (
                    <Form.Row>
                      <InputViewer
                        id="extExtra03"
                        name="extExtra03"
                        label="Extra 03:"
                        asRow
                      />
                    </Form.Row>
                  )}
                  {itensCarrinho.extExtra04 && (
                    <Form.Row>
                      <InputViewer
                        id="extExtra04"
                        name="extExtra04"
                        label="Extra 04:"
                        asRow
                      />
                    </Form.Row>
                  )}
                  {itensCarrinho.extObservacao && (
                    <Form.Row>
                      <InputViewer
                        id="extObservacao"
                        name="extObservacao"
                        type="textarea"
                      />
                    </Form.Row>
                  )}
                </Form.Column>
              </Section>

              <Footer>
                {
                  // profile.userId &&
                  //   profile.name &&
                  (empresa.idEmpresa === opEmpresa.ASSISTE ||
                    empresa.idEmpresa === opEmpresa.DMB ||
                    empresa.idEmpresa === opEmpresa.MEGATEC) && (
                    <Footer.Btn type="button" onClick={() => handleShared()}>
                      <MdOutlineShare id="icon" size={20} />
                      Compartilhar
                    </Footer.Btn>
                  )
                }

                <Footer.Btn type="button" onClick={() => handleExportar(false)}>
                  <FaFileCsv id="icon" size={20} />
                  Exportar CSV
                </Footer.Btn>

                <Footer.Btn type="button" onClick={() => handleEmail()}>
                  <MdOutlineEmail id="icon" size={20} />
                  Enviar E-mail
                </Footer.Btn>

                {/* <Footer.Btn
                  type="button"
                  id="btn-whatsapp"
                  onClick={() => handleWhatsApp()}
                >
                  <MdWhatsapp id="icon" size={25} />
                  Enviar WhatsApp
                </Footer.Btn> */}

                {(!app || action === opTipoAcao.INTEGRACAO_CATALOGO) && (
                  <Footer.Submit type="submit">
                    {action === opTipoAcao.INTEGRACAO_TOKEN
                      ? 'Solicitar Orçamento'
                      : 'Solicitar Peças'}
                  </Footer.Submit>
                )}
              </Footer>
            </Form>
          ) : (
            <NotFound>Nenhum item adicionado no carrinho</NotFound>
          )}
        </Main.Wrapper>
      </Main.Container>
    </Main>
  );
}
